<template>
  <v-content>
    <v-container fluid fill-height grid-list-lg>
      <v-layout class="mb-4 pb-4" wrap align-center justify-center>
        <v-flex xs12 sm10 md8 lg6 xl4>
          <v-card>
            <v-toolbar class="elevation-0" color="accent" dark dense>
              <v-toolbar-title class="subheading font-weight-regular">
                Buscar insumo
              </v-toolbar-title> </v-toolbar
            ><v-card-text>
              <form novalidate @submit.stop.prevent>
                <v-text-field
                  v-model="code"
                  outline
                  label="Codigo de insumo"
                  outlined
                  @keyup.enter="submitCode"
                ></v-text-field>
              </form>
              <p class="text-xs-center mt-2">o</p>
              <v-btn color="accent" flat block @click="openCam">
                Escanear QR
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog v-model="dialogCam" fullscreen width="500" persistent>
        <QrcodeStream :track="paintOutline" :torch="torch" @detect="onDetect">
          <div class="text-qr">
            <p>Apunte el código QR</p>
          </div>
          <v-btn fab dark fixed top right @click="torch = !torch">
            <v-icon v-if="torch">flash_on</v-icon>
            <v-icon v-else>flash_off</v-icon>
          </v-btn>
        </QrcodeStream>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'QrSupply',
  components: { QrcodeStream },
  data() {
    return {
      title: 'Inventarios realizados',
      dialogCam: false,
      torch: false,
      code: null
    }
  },
  watch: {
    $route(e) {
      if (e.query.cam) {
        this.dialogCam = true
      }
      if (!e.query.cam) {
        this.dialogCam = false
      }
    }
  },
  mounted() {
    if (this.$route.query.cam) {
      this.dialogCam = true
    }
  },
  methods: {
    async onDetect(promise) {
      this.$router.push({ query: {} })
      try {
        const { content } = await promise
        const code = content
        this.$router.push({ name: 'QRStockID', params: { code } })
      } catch (error) {
        this.$router.push({ query: {} })
      }
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    openCam() {
      this.$router.push({ query: { cam: 1 } })
    },
    submitCode() {
      const { code } = this
      this.$router.push({ name: 'QRStockID', params: { code } })
    }
  }
}
</script>

<style scoped>
.text-qr {
  position: absolute;
  bottom: 20px;
  background: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  width: 170px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.text-qr p {
  margin: 0;
}
</style>
